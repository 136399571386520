<template>
  <div class="role">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>帐号管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search-bar">
      <div class="left">
        <el-form :inline="true" :model="query" class="search-form-inline">
          <el-form-item>
            <el-input v-model="query.title" placeholder="请输入角色标题" clearable @clear="getRoleList"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getRoleList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="right">
        <el-button type="primary" @click="addVisible = true">新增</el-button>
      </div>
    </div>
    <div class="page-content">
      <el-table :data="roleList" border style="width: 100%" max-height="500">
        <el-table-column type="index" label="#" width="180"></el-table-column>
        <el-table-column prop="title" label="角色名称"></el-table-column>
        <el-table-column prop="is_default" label="是否为默认" width="180">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_default" active-color="#13ce66" inactive-color="#ff4949" :active-value="2" :inactive-value="1" @change="defautlChange(scope.row.id, $event)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="small" @click="editItem(scope.row.id)">编辑</el-button>
            <el-button type="danger" size="small" @click="delItem(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加角色弹窗 -->
    <el-dialog title="添加角色" :visible.sync="addVisible" width="30%" :before-close="addClose">
      <el-form :model="addForm" :rules="addRules" ref="addRef" class="add-form" label-position="top">
        <el-form-item label="角色名称" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-input label="备注" prop="desc" type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入备注" v-model="addForm.desc"></el-input>
        <el-form-item label="可使用权限" prop="authList" style="text-align: left">
          <el-tree ref="addThree" :data="authList" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false" @check-change="addThreeClickChange">
            <span class="custom-tree-node three-item" slot-scope="{ node, data }">
              <span>{{ data.title }}</span>
              <span class="auth-type">
                 <el-tag type="success" size="mini" v-if="data.auth_type === 'menu'">菜单</el-tag>
                 <el-tag type="warning" size="mini" v-if="data.auth_type === 'front'">前端</el-tag>
                 <el-tag type="danger" size="mini" v-if="data.auth_type === 'backend'">后端</el-tag>
              </span>
              <span class="method">
                <el-tag size="mini">{{ data.method }}</el-tag>
              </span>
            </span>
          </el-tree>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addSubmit('addRef')">新增角色</el-button>
          <el-button @click="cancellAdd">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 修改权限路由弹窗 -->
    <el-dialog title="编辑角色" :visible.sync="editVisible" width="30%" :before-close="editClose">
      <el-form :model="editForm" :rules="editRules" ref="editRef" class="edit-form" label-position="top">
        <el-form-item label="权限名称" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-input prop="desc" label="备注" type="textarea" :autosize="{ minRows: 4, maxRows: 4}" placeholder="请输入备注" v-model="editForm.desc"></el-input>
        <el-form-item label="可使用权限" prop="authChecked" style="text-align: left">
          <el-tree ref="editThree" :data="authList" show-checkbox node-key="id" :default-checked-keys="editForm.authChecked" :props="defaultProps" @check-change="editThreeClickChange"></el-tree>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="editSubmit('editRef')">修改角色</el-button>
          <el-button @click="cancellEdit">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Role',
  data () {
    return {
      authList: [],
      roleList: [],
      query: {
        title: ''
      },
      addForm: {
        title: '',
        desc: '',
        authList: []
      },
      editForm: {
        id: 0,
        title: '',
        desc: '',
        authChecked: []
      },
      addRules: {
        title: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 3, max: 8, message: '长度在 3 到 8 个字符', trigger: 'blur' }
        ]
      },
      editRules: {
        title: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 3, max: 8, message: '长度在 3 到 8 个字符', trigger: 'blur' }
        ]
      },
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      // 添加弹窗
      addVisible: false,
      // 编辑弹窗
      editVisible: false
    }
  },
  created () {
    this.getRoleList()
    this.getAuthList()
  },
  methods: {
    // 获取角色列表
    async getRoleList () {
      const { data: res } = await this.$http.get('role-list', { params: this.query })
      if (res.code === 200) {
        this.roleList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取权限规则列表
    async getAuthList () {
      const { data: res } = await this.$http.get('children-auth')
      if (res.code === 200) {
        this.authList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    async editItem (id) {
      const { data: res } = await this.$http.get('role/' + id)
      if (res.code === 200) {
        console.log(res.data)
        this.editForm.id = res.data.id
        this.editForm.title = res.data.title
        this.editForm.authChecked = res.data.casbin_list
        this.editForm.desc = res.data.desc
        this.editVisible = true
      }
    },
    async delItem (id) {
      const { data: res } = await this.$http.delete('role/' + id)
      if (res.code === 200) {
        this.$message.success(res.msg)
        await this.getRoleList()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭弹窗
    addClose (done) {
      this.$refs.addRef.resetFields()
      this.$nextTick(() => {
        this.$refs.addThree.setCheckedKeys([])
      })
      done()
    },
    // 关闭弹窗
    editClose (done) {
      this.$refs.editRef.resetFields()
      this.$nextTick(() => {
        this.$refs.editThree.setCheckedKeys([])
      })
      done()
    },
    // 添加规则
    addSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('role/0', this.addForm)
          if (res.code === 200) {
            await this.getRoleList()
            this.$refs.addRef.resetFields()
            this.$nextTick(() => {
              this.$refs.addThree.setCheckedKeys([])
            })
            this.addVisible = false
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消添加角色
    cancellAdd () {
      this.$refs.addRef.resetFields()
      this.$nextTick(() => {
        this.$refs.addThree.setCheckedKeys([])
      })
      this.addVisible = false
    },
    // 编辑角色
    editSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.put('role/' + this.editForm.id, this.editForm)
          if (res.code === 200) {
            await this.getRoleList()
            this.$refs.editRef.resetFields()
            this.$nextTick(() => {
              this.$refs.editThree.setCheckedKeys([])
            })
            this.$message.success(res.msg)
            this.editVisible = false
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 取消编辑规则
    cancellEdit () {
      this.$refs.editRef.resetFields()
      this.$nextTick(() => {
        this.$refs.editThree.setCheckedKeys([])
      })
      this.editVisible = false
    },
    // 添加角色弹窗的three组件被点击时的回调
    addThreeClickChange (obj, node, our) {
      const res = this.$refs.addThree.getCheckedKeys().concat(this.$refs.addThree.getHalfCheckedKeys())
      console.log(this.$refs.addThree.getNode())
      this.addForm.authList = res
    },
    // 编辑角色弹窗的three组件被点击时的回调
    editThreeClickChange (obj, node, our) {
      const res = this.$refs.editThree.getCheckedKeys()
      this.editForm.authChecked = res
    },
    // 设置是否为默认角色
    async defautlChange (id, newval) {
      const { data: res } = await this.$http.post('change-default', { id: id, newval: newval })
      if (res.code === 200) {
        this.$message.success(res.msg)
        await this.getRoleList()
      } else {
        this.$message.error(res.msg)
        await this.getRoleList()
      }
    }
  }
}
</script>

<style scoped>
.search-bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-form-inline .el-form-item{
  margin-bottom: 0;
}
.add-form{
  padding: 0 40px;
  text-align: left;
}
.edit-form{
  padding: 0 40px;
  text-align: left;
}
/deep/.el-tree-node{
  margin: 6px 0;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
.el-form-item{
  margin-bottom: 8px;
}
.three-item{
  width: 100%;
}
.three-item .auth-type {
  margin-left: 6px;
}
.three-item .method{
  float: right;
}
</style>
